import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'reactstrap'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet
      title="Guide Turistiche Salzkammergut - Austria Guides"
      meta={[
        { name: 'description', content: 'Discover the greatest guided tours in the Salzkammergut, Hallstatt, Bad Goisern, Bad Ischl, Bad Aussee, St. Wolfgang, Gmunden, Salzburg, Linz and many more.' },
        { name: 'keywords', content: 'city and town tours, bus tours, themed tours, torch-lit-tours, seasonal tours, children and school group tours, social programmes for seminars and conferences, Austria Guides' },
      ]}>
    </Helmet>
    <div>
      <div className="position-relative">
        <div className="d-flex align-items-end w-100 h-100 position-absolute" style={{ zIndex: 100 }}>
          <div className="w-100">
            <svg viewBox="0 -60 1500 200" style={{ marginBottom: '-10px' }}>
              <g>
                <path d="m-9.5,118.74482l507.5,-3.13793l304,-66.52413l36,22.5931l101,-42.67586l76,51l76,-38.44828l71,32.63449l101,22.5931l239,53.22069l4,60.99999l-1515,0l-0.5,-92.25517z" fill="#fff" />
              </g>
            </svg>
          </div>
        </div>
        <Img fluid={data.imageHead.childImageSharp.fluid} className="img-fluid" alt="Hallstatt" />
      </div>

      <Container className="pb-0 py-lg-4 mt-5 mb-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-4">
            <Img fluid={data.imageTeam.childImageSharp.fluid} className="img-fluid rounded" alt="Salzkammergut Austria Guides" />
          </div>
          <div className="offset-md-1 col-md-7 mt-4 mt-md-0">
            <h6 className="text-uppercase">Salzkammergut</h6>
            <h2 className="text-uppercase mb-3">Guide Turistiche</h2>
            <p className="text-justify">
              Siamo un piccolo gruppo di <strong>guide turistiche</strong> abilitate che operano nella regione del Salzkammergut. Offriamo ai nostri clienti servizi di accompagnamento turistico e visite guidate di mezza giornata o di un´intera giornata nelle seguenti lingue straniere: <strong>italiano, tedesco, inglese e francese</strong>.
            </p>
          </div>
        </div>
      </Container>

      <hr />

      <Container className="py-0 py-lg-4">
        <div className="row my-5">
          <div className="col-md-7">
            <h6 className="text-uppercase">METE TURISTICHE E ITINERARI</h6>
            <h1 className="mb-3 text-uppercase">ALLA SCOPERTA<br /> DELL´AUSTRIA</h1>
            <p className="text-justify">
              Qui trovate una piccola selezione delle nostre mete turistiche. Iniziando con <Link to="/it/tours/hallstatt/">Hallstatt</Link>, passando per <Link to="/it/tours/bad-ischl/">Bad Ischl</Link> fino a raggiungere la bella regione <span class="text-nowrap">dell´</span> <Link to="/en/tours/bad-aussee/">Ausseeerland</Link>. O preferite una città più grande come Salisburgo o Linz? Potete <Link to="/it/contact/">contattarci</Link> per definire insieme una gita personalizzata.
            </p>

            <Link to={`/it/tours/`} className="btn btn-danger">I nostri itinerari</Link>
          </div>
          <div className="col-md-4 offset-md-1 mt-4 mt-md-0">
            <h6 className="text-uppercase mb-3">NOVITÀ</h6>
            <h5 className="h6 mt-3"><Link to={`/it/tours/hallstatt/`} className="color-primary">Hallstatt in barca e a piedi</Link></h5>
            <h5 className="h6 mt-3"><Link to={`/it/tours/hallstatt/`} className="color-primary">Visite tematiche</Link></h5>
            <Link to={`/it/tours/hallstatt/`} className="btn btn-danger btn-sm">ulteriori informazioni</Link>
          </div>
        </div>

      </Container>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    imageHead: file(relativePath: { eq: "hallstatt-start-morgenstunde.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1250, maxHeight: 420, quality: 85, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTeam: file(relativePath: { eq: "hallstatt-guides-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTorchLitTour: file(relativePath: { eq: "torch-lit-tour.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
